<template>
    <div class="join">
      <div class="content">
            <div
                class="img"
                v-for="item in 1"
                :key="item"
                :style="getStyle(item)"
            >
                <img :src="require(`./image/icon_content_zs_${item}.png`)" >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        getStyle (index) {
            let imgUrl = require(`./image/icon_bg_zs_${index}.png`);
            return { 'background-image': 'url(' + imgUrl + ')' };
        },
    },
};
</script>

<style scoped lang="scss">
.join {
  .content {
    .img {
        text-align: center;
        padding: 60px 0;
        img {
            max-width: 1200px;
        }
    }
  }
}
</style>
